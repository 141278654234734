var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "mt-2",
      attrs: { rounded: "xl", ripple: false, to: _vm.to },
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { staticClass: "align-center", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-truncate",
                  attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 6 },
                },
                [
                  _c("ul", [
                    _c("li", [
                      _c("b", [_vm._v(" Mã Debit")]),
                      _c("span", [_vm._v(": " + _vm._s(_vm.item.debitNo))]),
                    ]),
                    _c("li", [
                      _c("b", [_vm._v(_vm._s(_vm.$t("row-voyage")))]),
                      _c("span", [_vm._v(": " + _vm._s(_vm.item.voyageCode))]),
                    ]),
                    _c("li", [
                      _c("b", [
                        _vm._v(" " + _vm._s(_vm.$t("row-polpod")) + " "),
                      ]),
                      _c("span", { staticClass: "text-uppercase" }, [
                        _vm._v(
                          ": " +
                            _vm._s(_vm.item.polCode) +
                            " - " +
                            _vm._s(_vm.item.podCode) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("b", [
                        _vm._v(" " + _vm._s(_vm.$t("row-payment")) + " "),
                      ]),
                      _c("span", { staticClass: "text-uppercase" }, [
                        _vm._v(": " + _vm._s(_vm.item.paymentType) + " "),
                      ]),
                    ]),
                    _vm.$vuetify.breakpoint.mobile
                      ? _c("li", [
                          _c("b", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("col-created-at")) + " "
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$filters.formatDate(_vm.item.debitDate)
                                ) +
                                " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _vm.$vuetify.breakpoint.mobile
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-center align-center mt-2",
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                color: "red",
                                small: "",
                                outlined: "",
                                dark: "",
                                tag: "a",
                                target: "_blank",
                                href: _vm.debitUrl,
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-file-pdf-box"),
                              ]),
                              _vm._v(" Debit"),
                            ],
                            1
                          ),
                          _vm.item.consigneeCode
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "red",
                                    small: "",
                                    outlined: "",
                                    dark: "",
                                    tag: "a",
                                    target: "_blank",
                                    href: _vm.noaUrl,
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-file-pdf-box"),
                                  ]),
                                  _vm._v(" NOA"),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              !_vm.$vuetify.breakpoint.mobile
                ? _c(
                    "v-col",
                    {
                      staticClass: "text-center text-truncate",
                      attrs: { cols: "3" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.$filters.formatDate(_vm.item.debitDate))
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              !_vm.$vuetify.breakpoint.mobile
                ? _c(
                    "v-col",
                    { staticClass: "text-left", attrs: { cols: "3" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            color: "red",
                            small: "",
                            outlined: "",
                            dark: "",
                            tag: "a",
                            target: "_blank",
                            href: _vm.debitUrl,
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-file-pdf-box"),
                          ]),
                          _vm._v(" Debit"),
                        ],
                        1
                      ),
                      _vm.item.consigneeCode
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "red",
                                small: "",
                                outlined: "",
                                dark: "",
                                tag: "a",
                                target: "_blank",
                                href: _vm.noaUrl,
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-file-pdf-box"),
                              ]),
                              _vm._v(" NOA"),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }