<template>
  <v-card
    class="mt-2"
    rounded="xl"
    :ripple="false"
    :to="to"
  >
    <v-card-text>
      <v-row
        no-gutters
        class="align-center"
      >
        <v-col
          :cols="$vuetify.breakpoint.mobile ? 12 : 6"
          class="text-truncate"
        >
          <ul>
            <li>
              <b> Mã Debit</b>
              <span>: {{ item.debitNo }}</span>
            </li>
            <li>
              <b>{{ $t('row-voyage') }}</b
              ><span>: {{ item.voyageCode }}</span>
            </li>

            <li>
              <b>
                {{ $t('row-polpod') }}
              </b>
              <span class="text-uppercase"
                >: {{ item.polCode }} - {{ item.podCode }}
              </span>
            </li>
            <li>
              <b> {{ $t('row-payment') }} </b>
              <span class="text-uppercase">: {{ item.paymentType }} </span>
            </li>
            <li v-if="$vuetify.breakpoint.mobile">
              <b>
                {{ $t('col-created-at') }}
              </b>
              <span>
                {{ $filters.formatDate(item.debitDate) }}
              </span>
            </li>
          </ul>
          <div
            v-if="$vuetify.breakpoint.mobile"
            class="d-flex justify-center align-center mt-2"
          >
            <v-btn
              class="mr-2"
              color="red"
              small
              outlined
              dark
              tag="a"
              target="_blank"
              :href="debitUrl"
            >
              <v-icon left>mdi-file-pdf-box</v-icon>
              Debit</v-btn
            >
            <v-btn
              v-if="item.consigneeCode"
              color="red"
              small
              outlined
              dark
              tag="a"
              target="_blank"
              :href="noaUrl"
            >
              <v-icon left>mdi-file-pdf-box</v-icon>
              NOA</v-btn
            >
          </div>
        </v-col>
        <v-col
          cols="3"
          class="text-center text-truncate"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <div>{{ $filters.formatDate(item.debitDate) }}</div>
        </v-col>
        <v-col
          cols="3"
          class="text-left"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <v-btn
            class="mr-2"
            color="red"
            small
            outlined
            dark
            tag="a"
            target="_blank"
            :href="debitUrl"
          >
            <v-icon left>mdi-file-pdf-box</v-icon>
            Debit</v-btn
          >
          <v-btn
            v-if="item.consigneeCode"
            color="red"
            small
            outlined
            dark
            tag="a"
            target="_blank"
            :href="noaUrl"
          >
            <v-icon left>mdi-file-pdf-box</v-icon>
            NOA</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import i18n from '@/i18n';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    to: {
      type: Object,
    },
  },
  components: {},
  data: () => ({}),
  computed: {
    debitUrl() {
      return `${process.env.VUE_APP_VSL_API}/v1/debits/files?recipient_id=${this.item.id}&file_type=DEBIT`;
    },
    noaUrl() {
      return `${process.env.VUE_APP_VSL_API}/v1/debits/files?recipient_id=${this.item.id}&file_type=NOA`;
    },
  },
  async created() {},
  methods: {},
};
</script>

<style scoped>
ul {
  list-style: none;
  display: table;
  width: 100%;
  table-layout: fixed;
  padding-left: 8px;
}

li {
  display: table-row;
}

b {
  display: table-cell;
  width: 155px;
}

span {
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
